import './App.css';
import FormHotelAnriva from "./FormHotels/FormHotelAnriva";
import FormHotelMaratuk from "./FormHotels/FormHotelMaratuk";
import FormHotelSkyTour from "./FormHotels/FormHotelSkyTour";
import WinnerHotel from "./FormHotels/WinnerHotel";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FormHotelAnriva />
        <FormHotelMaratuk />
        <FormHotelSkyTour />
        <WinnerHotel />
      </header>
    </div>
  );
}

export default App;




