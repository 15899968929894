import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import DatePickerField from "../components/DatePicker";
import * as Yup from "yup";

export default function FormHotelSkyTour() {
  const baseUrl = "https://fly.vardantravel.am/api";
  const [cityFrom, setCityFrom] = useState([]);
  const [cityTo, setCityTo] = useState([]);
  const [nationalityData, setNationalityData] = useState([]);
  const [freeDate, setFreeDate] = useState([]);
  const [freeDateTo, setFreeDateTo] = useState([]);
  const [allHotels, setAllHotels] = useState([]);
  const [childOptions, setChildOptions] = useState([]);
  const [loading, setLoading] = useState(null);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [providerDataList, setProviderDataList] = useState({
    'sky-tour': [],
  });

  const adultData = [
    {count: 1 },
    {count: 2 },
    {count: 3 },
    {count: 4 },
  ]

  const validationSchema = Yup.object().shape({
    source_city_id: Yup.string().required('Country From is required'),
    destination_city_id: Yup.string().required('Country To is required'),
    nationality: Yup.string().required('Nationality is required'),
    date_departure: Yup.string().required('Date of departure is required'),
    date_return: Yup.string().required('Date of return is required'),
    adult: Yup.string().required('Number of adults is required'),
    children_ages: Yup.array()
      .of(
        Yup.string().required("Each child's age is required")
      )
      .when('children', {
        is: (value) => value > 0,
        then: schema => schema.min(Yup.ref('children'), 'All children\'s ages are required').required('Children ages are required'),
        otherwise: schema => schema.notRequired()
      }),
  });

  const providerData =  ['sky-tour']

  const handleAdultChange = (event) => {
    const selectedAdult = event.target.value;
    formik.setFieldValue('adult', selectedAdult);

    let updatedChildOptions;

    switch (parseInt(selectedAdult)) {
      case 1:
      case 2:
        updatedChildOptions = [1, 2];
        break;
      case 3:
        updatedChildOptions = [1];
        break;
      case 4:
        updatedChildOptions = [];
        break;
      default:
        updatedChildOptions = [];
    }

    setChildOptions(updatedChildOptions);
  };


  const formik = useFormik({
    initialValues: {
      source_city_id: "",
      destination_city_id: "",
      nationality: "",
      date_departure: "",
      date_return: "",
      hotel_id: '',
      children: '',
      adult: '',
      children_ages: [],
      provider: ''
    },
    validationSchema,
    validate: (values) => {
      let errors = {};
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (err) {
        if (err.inner && err.inner.length > 0) {
          errors = err.inner.reduce((acc, curr) => {
            return { ...acc, [curr.path]: curr.message };
          }, {});
        }
      }
      if (Object.keys(errors).length > 0) {
        setLoading(null);
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const params = new URLSearchParams();
        params.append("source_city_id", values.source_city_id);
        params.append("destination_city_id", values.destination_city_id);
        params.append("nationality", values.nationality);
        params.append("date_departure", values.date_departure);
        params.append("date_return", values.date_return);
        params.append("hotel_id", values.hotel_id);
        params.append("adult", values.adult || '');
        if (values.children && values.children > 0) {
          params.append("children", values.children);
          values.children_ages.forEach(age => {
            params.append("children_ages[]", age);
          });
        }

        if (values.provider) {
          params.append("provider", values.provider);
        }
        const response = await fetch(`${baseUrl}/tours?${params.toString()}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
          },
        });

        const data = await response.json();
        toast.success("This is search result");
        setLoading(null);
        if (values.provider) {
          setProviderDataList(prev => ({
            ...prev,
            [values.provider]: data.data
          }));
        }
      } catch (error) {
        setLoading(null)
        toast.error("Failed to search. Please try again.");
        console.error("Error:", error);
      }
    },
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/countries?only_from_id=1`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
        },
      })
      .then((response) => {
        setCityFrom(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  }, []);

  useEffect(() => {
    if (formik.values.source_city_id) {
      axios
        .get(`${baseUrl}/countries?exclude_from_id=${formik.values.source_city_id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
          },
        })
        .then((response) => {
          setCityTo(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [formik.values.source_city_id]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/nationality`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
        },
      })
      .then((response) => {
        setNationalityData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching nationalities:", error);
      });
  }, []);

  useEffect(() => {
    if (formik.values.source_city_id && formik.values.destination_city_id) {
      axios
        .get(
          `${baseUrl}/dates?source_city_id=${formik.values.source_city_id}&destination_city_id=${formik.values.destination_city_id}&provider=${providerData[0]}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
            },
          }
        )
        .then((response) => {
          setFreeDate(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching dates:", error);
        });
    }
  }, [formik.values.source_city_id, formik.values.destination_city_id]);

  useEffect(() => {
    if (
      formik.values.source_city_id &&
      formik.values.destination_city_id &&
      formik.values.date_departure
    ) {
      axios
        .get(
          `${baseUrl}/dates?source_city_id=${formik.values.source_city_id}&destination_city_id=${formik.values.destination_city_id}&from_date=${formik.values.date_departure}&provider=${providerData[0]}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
            },
          }
        )
        .then((response) => {
          setFreeDateTo(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching dates:", error);
        });
    }
  }, [
    formik.values.source_city_id,
    formik.values.destination_city_id,
    formik.values.date_departure,
  ]);

  useEffect(() => {
    if(formik.values.source_city_id && formik.values.destination_city_id) {
      axios
        .get(`${baseUrl}/hotels`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer k8k2VKqKNnB1R5lpbkYBaSb8oGI0gb5ggzJNYkN3b3b0672d",
          },
        })
        .then((response) => {
          setAllHotels(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching hotels:", error);
        });
    }
  }, [formik.values.source_city_id, formik.values.destination_city_id]);

  useEffect(() => {
    const filtered = allHotels.filter(hotel =>
      hotel.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredHotels(filtered);
  }, [searchTerm, allHotels]);

  const addProviderKeyInFormik = (providerKey) => {
    setLoading(providerKey);

    formik.setFieldValue('provider', providerKey);
    formik.handleSubmit()
  }

  return (
    <form className="p-1 flex gap-2" onSubmit={formik.handleSubmit}>
      {providerData.map((item,index) => {
        return (
          <div key={index} className="max-w-[370px] space-y-3 border border-2 border-amber-400 p-1">
            <p>{item}</p>
            <div className="border-b border-white/10 pb-4">
              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="countryFrom"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Country From
                  </label>
                  <div className="mt-2">
                    <select
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                      id="source_city_id"
                      name="source_city_id"
                      value={formik.values.source_city_id}
                      onChange={formik.handleChange}
                    >
                      <option value="">From</option>
                      {cityFrom.map((state) =>
                        state.cities.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      )}
                    </select>
                    {formik.touched.source_city_id && formik.errors.source_city_id ? (
                      <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.source_city_id}</div>
                    ) : null}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="countryTo"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Country To
                  </label>
                  <div className="mt-2">
                    <select
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                      id="destination_city_id"
                      name="destination_city_id"
                      value={formik.values.destination_city_id}
                      onChange={formik.handleChange}
                    >
                      <option value="">To</option>
                      {cityTo.map((state) =>
                        state.cities.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      )}
                    </select>
                    {formik.touched.destination_city_id && formik.errors.destination_city_id ? (
                      <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.destination_city_id}</div>
                    ) : null}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="nationality"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Nationality
                  </label>
                  <div className="mt-2">
                    <select
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                      id="nationality"
                      name="nationality"
                      value={formik.values.nationality}
                      onChange={formik.handleChange}
                    >
                      <option value="">Nationality</option>
                      {nationalityData
                        .filter(item => item.code === "RU" || item.code === "RA")
                        .map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                    </select>
                    {formik.touched.nationality && formik.errors.nationality ? (
                      <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.nationality}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-white/10 pb-4">
              <div className="mt-1 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DatePickerField
                    freeDays={freeDate}
                    id={formik.values.date_departure}
                    formik={formik}
                    title="Date of departure"
                    formikId="date_departure"
                  />
                  {formik.touched.date_departure && formik.errors.date_departure ? (
                    <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.date_departure}</div>
                  ) : null}
                </div>
                {freeDateTo && freeDateTo.length > 0 ? <div className="sm:col-span-3">
                  <DatePickerField
                    id={formik.values.date_return}
                    formik={formik}
                    title="Date of return"
                    freeDays={freeDateTo}
                    formikId="date_return"
                  />
                    {formik.touched.date_return && formik.errors.date_return ? (
                      <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.date_return}</div>
                    ) : null}
                </div> :
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium text-white mb-4">Date of return</label>
                    <div className="mt-[10px] h-[38px] animate-pulse flex items-center text-sm space-x-4 border border-blue-300 shadow rounded-md p-4 w-full">
                      yyyy-MM-dd
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="nationality"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Adult
                </label>
                <div className="mt-2">
                  <select
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                    id="adult"
                    name="adult"
                    value={formik.values.adult}
                    onChange={handleAdultChange}
                  >
                    <option value="">Adult</option>
                    {adultData.map((item, index) => (
                      <option key={index} value={item.count}>
                        {item.count}
                      </option>
                    ))}
                  </select>
                  {formik.touched.adult && formik.errors.adult ? (
                    <div className="text-start error text-xs text-red-600 mt-2">{formik.errors.adult}</div>
                  ) : null}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="nationality"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Children
                </label>
                <div className="mt-2">
                  <select
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                    id="children"
                    name="children"
                    value={formik.values.children}
                    onChange={formik.handleChange}
                  >
                    <option value="">Children</option>
                    {childOptions.map((count, index) => (
                      <option key={index} value={count}>
                        {count}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {formik.values.children &&
                <div className="sm:col-span-6 flex gap-x-6">
                  {Array.from({ length: formik.values.children }).map((_, index) => (
                    <div key={index}>
                      <input
                        className="block w-full rounded-md border-0 bg-white/5 px-2 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black mb-2"
                        id={`children_ages.${index}`}
                        name={`children_ages.${index}`}
                        value={formik.values?.children_ages?.[index] || ''}
                        onChange={(e) => {
                          const newChildrenAges = [...formik.values.children_ages];
                          newChildrenAges[index] = e.target.value;
                          formik.setFieldValue("children_ages", newChildrenAges);
                        }}
                        placeholder={`Child ${index + 1} Age`}
                      />
                      {formik.errors.children_ages && formik.errors.children_ages[index] ? (
                        <div className="text-start error text-xs text-red-600 mt-2">
                          {formik.errors.children_ages}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              }
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="nationality"
                className="block text-sm font-medium leading-6 text-white"
              >
                Hotels name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  className="mb-2 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                  placeholder="Search hotels..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select
                  className="spase-y-2 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                  id="hotel_id"
                  name="hotel_id"
                  value={formik.values.hotel_id}
                  onChange={formik.handleChange}
                >
                  <option value="">Hotel Name</option>
                  {filteredHotels.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 flex items-center justify-end gap-x-6">
              <button
                onClick={() => addProviderKeyInFormik(item)}
                type="button"
                className="rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
              >
                Search
              </button>
            </div>
            {loading === item ? (
              <div className="border border-blue-300 shadow rounded-md p-4 mt-6 w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : providerDataList[item] && providerDataList[item].length > 0 ? (
              <div className="mt-6 w-full">
                <h3 className="text-xl font-semibold mb-4"> {providerDataList[item].length } Hotels result </h3>
                <ul className="space-y-4">
                  {providerDataList[item].map((dataItem, idx) => (
                    <li key={idx} className="bg-yellow-500 p-4 rounded-lg shadow-md text-black w-full">
                      <p className="text-lg font-medium mb-2">Hotel Name - {dataItem.name}</p>
                      <p className="text-sm text-gray-600 mb-2">Provider - {dataItem.provider}</p>
                      {dataItem?.feature_rooms.map((itemRoom, index) => (
                        <div key={index}>
                          <p className="text-sm font-semibold mb-2">Price - ${itemRoom.price}</p>
                          <div className="flex flex-col gap-y-1">
                            <p className="text-sm">Room Type - {itemRoom.name_secondary}</p>
                            <p className="text-sm">{itemRoom.name}</p>
                          </div>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              !loading && (
                <div className="text-lg text-center mt-4">No data available.</div>
              )
            )}
          </div>
        )
      })}
    </form>
  );
}
